import { render, staticRenderFns } from "./family-of-brands.vue?vue&type=template&id=916a1494&scoped=true&lang=pug&"
import script from "./family-of-brands.vue?vue&type=script&lang=coffee&"
export * from "./family-of-brands.vue?vue&type=script&lang=coffee&"
import style0 from "./family-of-brands.vue?vue&type=style&index=0&id=916a1494&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "916a1494",
  null
  
)

export default component.exports